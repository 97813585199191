import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import './style.scss'

import { get } from 'lodash'

export default class HeroSingle extends Component {
  render() {
    const { data, title, introTitle } = this.props
    this.introTitle = get(data, 'frontmatter.intro_title', introTitle)

    this.title = get(data, 'frontmatter.title', title)

    this.image =
      get(data, 'frontmatter.header_cover_image.childImageSharp.fluid', null) ||
      get(data, 'frontmatter.cover_image.childImageSharp.fluid', null)

    return (
      <section
        className={`c-hero-single c-hero-single--${!this.image && 'no-image'}`}
      >
        {this.image && (
          <Img
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
            }}
            fluid={this.image}
          />
        )}

        <div className="c-hero-single__content container position-relative pt-5 pb-2 pb-sm-3  d-flex flex-column h-100">
          <div
            className={`c-hero-single-content  my-auto ${
              this.image ? 'text-white py-3 py-md-5' : 'pt-3 pt-md-3'
            }`}
          >
            <div className="c-hero-single-content__inner pr-3">
              {this.introTitle && <h6>{this.introTitle}</h6>}
              <h1 className="c-hero-single-content__text">{this.title}</h1>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export const HeroSingleQuery = graphql`
  fragment HeroImage on File {
    childImageSharp {
      fluid(maxWidth: 1440, maxHeight: 500) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment HeroSingle on MarkdownRemark {
    frontmatter {
      intro_title
      title
      header_cover_image {
        ...HeroImage
      }
      cover_image {
        ...HeroImage
      }
    }
  }
  fragment HeroSingleMDX on Mdx {
    frontmatter {
      intro_title
      title
      header_cover_image {
        ...HeroImage
      }
      cover_image {
        ...HeroImage
      }
    }
  }
`
