import React from 'react'

import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import ContactForm from '../components/Forms/ContactForm'
import ContactMap from '../components/ContactMap'
import HeroSingle from '../components/HeroSingle'
import SEO from '../components/SEO/index'
import CTABanner from '../components/CTABanner'

class Index extends React.Component {
  render() {
    const { data, location } = this.props

    const {
      frontmatter: { title },
      html,
    } = data.page

    return (
      <Layout location={location}>
        <SEO
          path={location.pathname}
          frontmatter={data.page.frontmatter}
          description={data.page.excerpt}
        />
        <HeroSingle data={data.page} />

        <div className="container mt-5">
          <ContactForm />
        </div>

        <ContactMap
          loadingElement={<div style={{ height: `100%` }} />}
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
        />
        <div className="bg-offwhite py-3 py-lg-5">
          <div
            className="body mini-container text-center"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
        <div className="bg-light c-footer">
          <CTABanner data={data.page.frontmatter.cta_banner} />
        </div>
      </Layout>
    )
  }
}

export default Index

export const ContactQuery = graphql`
  query {
    page: markdownRemark(frontmatter: { id: { eq: "contact" } }) {
      ...HeroSingle
      ...SEO
      ...ctaBanner
      frontmatter {
        title
      }
      html
    }
  }
`
